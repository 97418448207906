import { NoAccessLock } from '@assets/icons';

const NoAccessPage = () => {
  return (
    <div className="flex flex-col h-screen w-screen text-center justify-center items-center">
      <NoAccessLock />
      <div className="text-2xl font-semibold mt-10 text-dark-1">Uh-oh!</div>
      <div className="text-xl font-semibold text-dark-1 mt-2">
        You don’t have access to this page.
      </div>
      <div className="text-base text-gray-1 mt-2">
        Request access from your administrator
      </div>
    </div>
  );
};

export default NoAccessPage;
